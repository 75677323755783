<template>
	<div id="dialog">
		<h3>Ways to Skip the Line</h3>
		<AccordeonList :listData="listData" :options="{ pad: true }" />
		<div class="buttons-wrap">
			<FormButton ref="submit" :level="1" value="Got it" @click="doCancel" />
		</div>
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import AccordeonList from '@/components/AccordeonList'
import FormButton from '@/components/FormButton'

export default {
	name: 'DialogTemplate',
	components: { AccordeonList, FormButton },
	// setup() {
	// 	const dialogStore = useDialogStore()
	// 	return { dialogStore }
	// },
	data() {
		return {
			listData: {
				'Donate <span class="soft"> &nbsp;-&nbsp; Guaranteed</span>': [
					'Make a one-time <a href="/donate">donation</a> of USD $20 or more.',
					'Become a patron on <a target="_blank" href="https://www.patreon.com/butisitarthur">Patreon</a>.',
				],
				'Spread the love <span class="soft"> &nbsp;-&nbsp; Depends on your effort</span>': [
					'Follows us on <a target="_blank" href="https://instagram.com/butisitarthur">Instagram</a> and/or <a target="_blank" href="https://twitter.com/butisitarthur">Twitter</a> and tag <b>@butisitarthur</b> in your posts and stories. Tag your cool friends to dramatically increase your chances.',
					'Keep an eye on our social media for additional early-access opportunities.',
				],
				'Apply <span class="soft"> &nbsp;-&nbsp; Selective</span>': [
					'It\'s ok to have no money or friends, as long as you make up with impeccable taste. <a target="_blank" href="https://forms.gle/ei9afevHZgEL3H3P6">Apply here</a>.',
				],
			},
		}
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, ['doCancel']),
	},
}
</script>

<style scoped lang="scss">
#dialog {
	width: 4rem;
}
#dialog h3 {
	margin-bottom: 0.3rem;
}
</style>
